import { useLocation } from "@solidjs/router";
import QrCodeScannerIcon from "@suid/icons-material/QrCodeScanner";
import { useTheme } from "@suid/material";
import { t } from "i18next";
import { Component, createSignal, lazy, Match, Switch } from "solid-js";
import { useAuth } from "src/hooks/useAuth";
import { Redirect } from "../challenges/Redirect";
import { Grid } from "../components";
import { Button } from "../components/Button";
import { CollapseButton } from "../components/CollapseButton";
import { LoginDialog } from "../components/LoginDialog";
import { config } from "../config";
import { MarkdownLink } from "../utilities/Parser/MarkdownLink";

const QrScannerDialog = lazy(async () => import("../components/QrScannerDialog/QrScannerDialog"));

export const Apps: Component = () => {
    const location = useLocation();
    let redirectUrl: URL | undefined;
    if (location.pathname === "/apps" && !config.debug.mockResponse) {
        const url = new URL(window.location.href);
        const expectedUrl = new URL(config.app.url ?? config.baseApi);
        if (expectedUrl.origin !== url.origin) {
            redirectUrl = expectedUrl;
        }
    }
    if (redirectUrl) {
        // eslint-disable-next-line solid/components-return-once
        return <Redirect url={redirectUrl?.toString() ?? ""}/>;
    }

    const theme = useTheme();
    const auth = useAuth();
    const [qrScannerEnabled, setQrScannerEnabled] = createSignal(false);

    return (<>
        <Switch>
            <Match when={!config.debug.mockResponse && !auth.isAuthenticated()}>
                <LoginDialog
                    id="ProwiseAppsPage"
                    title={t("overview.title.signing_in")}
                    showLanguageSelect={false}
                    primaryButton={<Button
                        data-testid="LoginButton"
                        color="secondary"
                        variant="contained"
                        disabled={auth.isLoading()}
                        onClick={() => void auth.login(true)}
                        sx={theme.mixins.button}
                    >
                        {t("general.btn.sign_in")}
                    </Button>}
                    errors={[]}
                />
            </Match>
            <Match when={true}>
                <LoginDialog
                    minContentWidth
                    id="ProwiseAppsPage"
                    title={<MarkdownLink text={t("overview.title.hello_{name}_{email}", { name: auth.getUser()?.profile.name, email: auth.getUser()?.profile.email })} />}
                    description={<MarkdownLink text={t("overview.p.where_to_go_{name}_{email}", { name: auth.getUser()?.profile.name, email: auth.getUser()?.profile.email })} />}
                    showLanguageSelect={false}
                    secondaryButton={
                        <Button
                            data-testid="LogoutButton"
                            color="secondary"
                            variant="outlined"
                            onClick={() => void auth.logout()}
                            sx={theme.mixins.buttonOutlined}
                        >
                            {t("general.btn.sign_out")}
                        </Button>
                    }
                    primaryButton={
                        <CollapseButton
                            data-testid="QrButton"
                            color="secondary"
                            variant="contained"
                            onClick={() => setQrScannerEnabled(true)}
                            sx={theme.mixins.button}
                            startIcon={<QrCodeScannerIcon/>}
                        >
                            {t("general.btn.open_qr_scanner")}
                        </CollapseButton>
                    }
                    errors={[]}
                >
                    <Grid
                        items={auth.getAppsCategorized()}
                        desktop={4}
                        mobile={4}
                        tileSize="75px"
                        round
                        showLabels
                        // @ts-expect-error - Single select item has meta
                        onSelection={({ meta }) => {
                            window.location.href = meta.url;
                        }}
                    />
                </LoginDialog>

                <QrScannerDialog
                    title={t("general.title.qr_scanner")}
                    open={qrScannerEnabled()}
                    onClose={() => setQrScannerEnabled(false)}
                />
            </Match>
        </Switch>
    </>);
};

export default Apps;
