import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@suid/material";
import i18next, { changeLanguage, t } from "i18next";
import {
    Component,
    createMemo,
    createSignal,
    JSX,
    lazy,
    Match,
    onMount,
    Setter,
    Show,
    Switch,
    type
    ParentComponent,
} from "solid-js";
import { LoginDialogProps } from "./Types";
import prowise_svg from "../../assets/prowise.svg";
import { config } from "../../config";
import { type BCP47 } from "../../utilities/defaultLocales";
import { MarkdownLink } from "../../utilities/Parser/MarkdownLink";
import { isPdfJsSupported } from "../../utilities/UserAgentHelper";
import { AppFrame } from "../AppFrame";
import { chromeBookQuery } from "../AppFrame/AppFrame.styled";
import { DebugErrors } from "../DebugErrors";
import { LocaleMenu } from "../LocaleMenu";

const PdfModalDialog = lazy(async () => import("../PdfModalDialog/PdfModalDialog"));
const MessageModalDialog = lazy(async () => import("../MessageModalDialog/MessageModalDialog"));

const PrivacyTermsModal: Component<{ pdfResource?: string; open?: boolean; onClose?: () => void }> = (props) => {
    const theme = useTheme();
    // Try and open the PDF modal if supported.
    // Note that since PdfModalDialog contains imports that may break older browsers,
    // we have a separate modal for error notifications.
    return <Show when={isPdfJsSupported()} fallback={
        <MessageModalDialog
            titleButton={
                <Button
                    href={props.pdfResource}
                    variant="contained"
                    color="secondary"
                    sx={theme.mixins.button}
                    title={t("general.btn.download_document_pdf")}
                >
                    {t("general.btn.download_document_pdf")}
                </Button>
            }
            open={!!props.pdfResource}
            onClose={props.onClose}
        >
            {t("error.p.pdf_rendering_unsupported")}
        </MessageModalDialog>}><PdfModalDialog
            pdfResource={props.pdfResource}
            open={!!props.pdfResource}
            onClose={props.onClose}
        /></Show>;
};

const Footer: Component<{ setPdf: Setter<string | undefined>; padRight?: boolean }> = (props) => {
    const theme = useTheme();

    const version = config.version.env && config.version.env !== "production"
        ? `${config.version.env} - ${config.version.number}`
        : config.version.number;

    return (<footer>
        <Typography sx={theme.mixins.typography} variant="caption2" as="span">{t("general.p.version_{version}", { version })}</Typography>
        <MarkdownLink
            variant="caption2"
            color="inherit"
            sx={{ ...theme.mixins.typography, pl: 1.5, pr: props.padRight ? 2.5 : 0 }}
            text={t("general.a.privacy_and_terms")}
            onClick={(event) => { props.setPdf(event.target.href); event.preventDefault(); }}
        />
    </footer>);
};

export const LoginDialog: ParentComponent<LoginDialogProps> = (props) => {
    const theme = useTheme();
    const [pdf, setPdf] = createSignal<string>();

    let frameRef: HTMLElement | undefined;

    // Less than 600px width
    const isSmDownBreakpoint = useMediaQuery(theme.breakpoints.down("sm"));

    // Note: apart from resolution, we might want to check user agent
    const isChromebook = useMediaQuery(`@media (${chromeBookQuery})`);

    // Allow arbitrary height when the default height (including footer that uses 2rem) does not fit.
    // Note: using `calc` in media queries is not according to spec and not allowed in vitest
    const isHeightBreakPoint = useMediaQuery("@media (max-height: 880px)");

    const fullHeight = createMemo(() => isSmDownBreakpoint() || props.fullHeight);
    const fullscreen = createMemo(() => isSmDownBreakpoint() || isChromebook() || props.fullscreen);
    const isHorizontal = createMemo(() => props.orientation === "horizontal");

    const handleClose = (): void => {
        setPdf(undefined);
    };

    // NOTE: Sequential mounts don't do autofocus; `keyed` properties have no effect
    // and we don't have a Dialog to set `disableRestoreFocus` on.
    // Also, due to some reactive properties, the UI is not fully drawn immediately,
    // so we need to delay the actual focus.
    onMount(() => {
        requestAnimationFrame(() => {
            document.title = typeof props.title === "string" ? `${props.title} – ${config.version.productName}` : config.version.productName;
            const element = document?.querySelector<HTMLInputElement>("[autofocus]");
            element?.focus();
        });
    });

    // TODO: Cleanup styling
    const Head = (): JSX.Element => <Box textAlign={isHorizontal() || isChromebook() ? undefined : "center" } sx={
        isChromebook() && !isHorizontal()
            ? { position: "absolute", left: theme.spacing(4), right: theme.spacing(4), top: isChromebook() ? "32px" : undefined }
            : { position: "relative", pb: isChromebook() ? "128px" : 4 }
    }>
        <img role="banner" height="30px" src={prowise_svg} />
        <Show when={props.showLanguageSelect !== false}>
            <LocaleMenu
                variant="text"
                showFlag
                sx={{ position: "absolute", right: theme.spacing(isHorizontal() ? 0 : -3.5), top: theme.spacing(isHorizontal() ? 0 : -3.5) }}
                sxButton={{
                    p: 0,
                    minWidth: isHorizontal() ? "30px" : theme.spacing(5),
                    height: isHorizontal() ? "30px" : theme.spacing(5),
                }}
                name="Language"
                default={i18next.language as BCP47}
                handleChange={(locale: string) => {
                    // Optionally, check if the chosen language exists in i18n.
                    const changeLang = async (): Promise<void> => {
                        await changeLanguage(locale);
                        document.location.reload();
                    };
                    void changeLang();
                    return locale;
                }}
            />
        </Show>
    </Box>;
    const Title = (): JSX.Element => <Show when={props.title}>
        <Typography data-testid="title" sx={{ ...theme.mixins.typography, mb: 4, overflow: "hidden", overflowWrap: "break-word" }} variant={props.titleVariant ?? "h2"} align={isHorizontal() ? undefined : "center"} color="primary">{props.title}</Typography>
    </Show>;
    const Link = (): JSX.Element => <Show when={props.link}>
        <Typography sx={{ ...theme.mixins.typography, mt: -3, mb: 4.5, overflow: "hidden", overflowWrap: "break-word" }} variant="body2" align={isHorizontal() ? undefined : "center"} color="primary">{props.link}</Typography>
    </Show>;
    const Description = (): JSX.Element => <Show when={typeof props.description === "string"} fallback={props.description}>
        <Typography sx={{ ...theme.mixins.typography, mb: 3, overflow: "hidden", overflowWrap: "break-word" }} variant="body2" align={isHorizontal() ? undefined : "center"} color="primary">{props.description}</Typography>
    </Show>;
    const Buttons = (): JSX.Element => <Stack
        justifyContent="space-between"
        alignItems={fullHeight() ? "end" : "begin"}
        rowGap={2}
        columnGap={2}
        flexWrap="wrap-reverse"
        direction="row"
        sx={{ mt: 2, flex: isHorizontal() || !fullHeight() ? "auto" : undefined }}
    >
        <span>
            {props.secondaryButton}
        </span>
        <span>
            {props.primaryButton}
        </span>
    </Stack>;

    return (
        <AppFrame
            ref={frameRef}
            fullWidth={props.fullWidth}
            minContentWidth={props.minContentWidth}
            minContentHeight={props.minContentHeight || isHeightBreakPoint()}
            fullscreen={fullscreen()}
            data-testid={props.id}
            footer={<Footer setPdf={setPdf} padRight />}
        >
            <Switch fallback={
                <div
                    class="container"
                    style={{
                        display: "flex",
                        "flex-direction": "column",
                        flex: "auto",
                        gap: theme.spacing(0),
                        "max-height": "100%",
                    }}
                >
                    <Head />
                    <Title />
                    <Link />
                    <Description />
                    <div
                        class="children"
                        style={{ flex: fullHeight() ? "auto" : undefined, display: "flex", "flex-direction": "column", overflow: "auto" }}
                    >
                        {props.children}
                    </div>
                    <Buttons />
                    <DebugErrors errors={props.errors} />
                    <Show when={fullscreen()}>
                        <div class="footer" style={{ "text-align": "end" }}>
                            <Footer setPdf={setPdf} />
                        </div>
                    </Show>
                </div>
            }>
                <Match when={isHorizontal()}>
                    <div
                        class="container"
                        style={{
                            display: "flex",
                            overflow: "hidden",
                            "flex-direction": "row",
                            gap: theme.spacing(2),
                            flex: "auto",
                        }}
                    >
                        <div
                            class="panel"
                            style={{ display: "flex", "flex-direction": "column", width: "400px", padding: theme.spacing(1.5, 0, 1.5, 1.5) }}
                        >
                            <Head />
                            <Title/>
                            <Link/>
                            <Description/>
                            <Buttons/>
                            <DebugErrors errors={props.errors} />
                            <Show when={fullscreen()}>
                                <div class="footer">
                                    <Footer setPdf={setPdf} />
                                </div>
                            </Show>
                        </div>
                        <div
                            class="panel"
                            style={{ flex: "auto", display: "flex", "flex-direction": "column", overflow: "auto" }}
                        >
                            {props.children}
                        </div>
                    </div>
                </Match>
            </Switch>
            {pdf() ? <PrivacyTermsModal
                pdfResource={pdf()}
                open
                onClose={handleClose}
            /> : null}
        </AppFrame>
    );
};
